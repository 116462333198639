//-----------------------------------------------------------------
// Licensed Materials - Property of IBM
//
// WebSphere Commerce
//
// (C) Copyright IBM Corp. 2016 All Rights Reserved.
//
// US Government Users Restricted Rights - Use, duplication or
// disclosure restricted by GSA ADP Schedule Contract with
// IBM Corp.
//-----------------------------------------------------------------

header.storeInit = function() {

};

header.mainCategoryPaddingBottom = 50;

header.adjustAssortmentDropdown = function(element) {
    var select = $(element);
    var searchInput = select.closest('.js-hook-headerSearchContainer').find('.js-hook-headerSearch');
    var spacerText = select.val() ?
                     select.find('option[value=' + select.val() + ']').text().trim() :
                     select.children().first().text().trim();

    $('.js-hook-headerSearchBass-spacer').html(spacerText);
    $('.js-hook-branch-assortment-search-dropdown').prop('title', spacerText);

    setTimeout(function() {
        searchInput.css({ paddingLeft: Math.min($('.js-hook-branch-assortment-search-dropdown').width(), 150) + 10 });
    });
};

header.initMegaMenuViews = function(megaMenuArr, appendDataCallback, showViewCallback, serviceFunction, finallyCallback){
	
	//The views are initialized in a nested fashion.
	//Ie the callback function places the next ajax request (through services.megaMenuView).
	//This is to ensure proper initialization. 
	//If it is not done this way the result is unpredictable. Not sure exactly why though.
	
	var initMegaMenuView = function(i, j){
		
		var bassId = "";
		
		if(j > -1){
			
			bassId = $(megaMenuArr[i]).data('bassids')[j];
		
		}
		
		try{
			
			serviceFunction($(megaMenuArr[i]).data('categoryid'), function(data){
							
				if(data.bassIdArr==null){
					
					$(megaMenuArr[i]).html(data.returnedAjaxData);
					
					if(megaMenuArr[i+1] != null){
						
						//initialize next mega menu view
						initMegaMenuView(i+1, -1);
						
					}else{
						
						//no more mega menu views to initialize
						finallyCallback();
						
					}
					
				}else{
					
					var bassIdArr = data.bassIdArr;
					
					if(j == -1){
						
						appendDataCallback(data, megaMenuArr[i]);
						initMegaMenuView(i, j+1);
						
					}else{
						
						appendDataCallback(data, megaMenuArr[i]);
						
						if(bassIdArr[j+1] != null){
							
							//initialize next assortment view
							initMegaMenuView(i, j+1);
						
						}else{ 
							
							//reaching here means that all assortment views for this mega menu view have been initialized
							showViewCallback(megaMenuArr[i]);
							
							if(megaMenuArr[i+1] != null){
								
								//initialize next mega menu view
								initMegaMenuView(i+1, -1);
							
							}else{
								
								//no more mega menu views to initialize
								finallyCallback();
								
							}
						}
					}
								
				}
	
			}, bassId, $(megaMenuArr[i]).data('bassids'));
			
		} catch(err) {
			
			console.log("ERROR in initMegaMenuView: "+err);
			finallyCallback();
		
		}
		
	}
	
	initMegaMenuView(0, -1);
	
}

header.initMobileBassMenuViews = function(urlRoot, callback) {
		
	var megaMenuMobileElements = $(".mega-menu-mobile[data-bassid]").toArray();
	var promises = [];
			
	for(var i=0,l=megaMenuMobileElements.length; i<l; i++){
		
		var element = megaMenuMobileElements[i];
		var promise = services.megaMenuCategoryList(urlRoot, element);

        promises.push(promise);
        
    }
	
    $.when.apply($, promises).then(function() {
    	
    	callback();

    });

}

header.init = function(urlRoot) {
	
	//initialize all mega menu views
	var megaMenuArr = $('.js-hook-megamenu').toArray();
	header.initMegaMenuViews(megaMenuArr, function(data, element){
		
		//here we control where the ajax data is placed
		var assortmentContent = $('<div class="branch-assortment__mega-menu js-hook-assortment-content" data-assid="' + data.bassId + '" style="display:none">' + data.returnedAjaxData + '</div>' );
	    $(element).append(assortmentContent);
		
	}, function(element){
		
		//here we control the initial display of menu views containing assortments
		//ie show the sub-view containing all products as well as the assortment navigation tabs
		$('.js-hook-assortment-content[data-assid=""]', element).show();
		$('.js-hook-assortment-nav', element).show();
		
	}, function(categoryId, serviceCallback, bassId, bassIdArr){
		
		//here we control which ajax resource that is used to fetch the data
		services.megaMenuView('AjaxMegaMenu', categoryId, serviceCallback, bassId, bassIdArr);
		
	}, function(){
		
		//here we control what happens at the end once everything is initialized
		header.initMegaMenu = false;
		$('.js-hook-spinner').hide();
		
	});	

    services.mobileMenuView(urlRoot, function(data){
        $(".js-hook-mobilemenu").html(data);
        header.initMobileBassMenuViews(urlRoot, function(){
        	$(".js-hook-mobileSpinner").removeClass("show");
            $(".js-hook-closeOverlay").removeClass("has-spinner");
            header.initMegaMenu = false;
        });
        
    });
    
    services.loginView(urlRoot, function(data) {
        $('.js-hook-headerMenuDrop-login').html(data);
        $('.js-hook-closeOverlay').removeClass('has-spinner');
    });

    //bind search input listers
    $('.js-hook-headerSearch').on('input', function(){
        header.autoSuggest();
    });
    $('.header .js-hook-sob-logonIdSearch').on('input', function(){
        header.shopOnBehalfAutoSuggest();
    });
    $('.header .js-hook-sob-firstNameSearch').on('input', function(){
        header.shopOnBehalfAutoSuggest();
    });
    $('.header .js-hook-sob-lastNameSearch').on('input', function(){
        header.shopOnBehalfAutoSuggest();
    });

    $('.js-hook-searchSubmit').on('click', function() {
        if ($('.js-hook-headerSearch').val().length > 0) {
            $('#searchBox').submit();
        }
    });
    $('.js-hook-headerLogin').on('click', function() {
        header.loginView();
    });
    $('.js-hook-closeOverlay').on('click', function() {
        $('.js-hook-closeOverlay').hide();
        $('.js-hook-sliderImage').hide();
        $('.js-hook-reqListContainer').addClass('hide');
        $('.js-hook-toggleTable').addClass('hide');
        $(".js-hook-table-menu").addClass("hide");
        $('body, html').removeClass('no-scroll');
    });
    $('.js-hook-headerSearchContainer').submit(function(event) {
        var searchTerm = $('.js-hook-headerSearch').val().trim();

        if (searchTerm.length > 0) {
            $('.js-hook-headerSearch').val(searchTerm);
            return true;
        } else {
            event.preventDefault();
        }
    });

    $('html').on('click', function(event) {
        var target = $(event.target);

        if (!$('.js-hook-headerSearch').is(':focus')) {
        	$('.js-hook-autoSuggestResult').hide();
        }
        
        if (!target.hasClass('header__e-drop')) {
        	$('.header__dropdown-more').hide();
        }

        if (!$(event.target).parents('.js-nav-MegaMenu').length && !$(event.target).parents('.js-hook-megamenu').length) {
	         	$('.js-hook-megamenu').hide();
	        }

        if (!$(event.target).is('.js-hook-table-links') && !$(event.target).parents('.js-hook-table-links').length && !$(event.target).parents('.js-hook-table-menu').length) {
	         	$('.js-hook-table-menu').addClass("hide");
	        }

        if (!$(event.target).parents('.js-autocomplete-org-hook').length && !$(event.target).is(".jsUser-autocomplete-org") && !$(event.target).is(".jsBecome-autocomplete-org")) {
         	$(".jsUser-autocomplete-org-result").hide();
         	$(".jsBecome-autocomplete-org-result").hide();
        }

    });

    $('.js-hook-headerSearch').on('focus', function(event) {
        $('.js-hook-autoSuggestResult').show();
        event.stopPropagation();
    });

    $('.js-hook-headerSearch').on('keydown', function(e) {
        if (e.keyCode == 40){
            e.preventDefault();
            $('.header__searchLink_suggestion').first().focus();
        }
    });

    var ckeFormValidator = new FormValidator('.js-cke-submit', '.js-cke-input');

    $('.js-cke-submit').on('click', function() {
    	var form = {};
    	var formId = $(this).data('form');

        $('.js-cke-input[data-form="' + formId + '"]').each(function(i) {
        	form[i + '-' + this.name] = this.value;
        });
        form.messageType = $(this).data('messagetype');
    	form.subject = $(this).data('subject');

        if (ckeFormValidator.validate(formId)) {
            services.sendEmail(form);
        }
    });

    /**
     * Binds navbar drop menus.
     */
    $('.js-hook-headerMenuDropButton').on('click', function(event) {
        
    	var overlay = $('.js-hook-closeOverlay'),
            button = $(event.currentTarget).not(overlay),
            body = $('body, html'),
            dropMenu = button.data('dropMenu'),
            menu, menu, menus;

        if (dropMenu) {
            buttons = $('.js-hook-headerMenuDropButton').not(button);
            targetMenu = button.data('dropMenu');
            menu = $('.js-hook-headerMenuDrop-' + dropMenu);
            menus = $('.js-hook-headerMenuDrop').not(menu);

            buttons.removeClass('show');
            button.toggleClass('show');
            menus.removeClass('show');
            menu.toggleClass('show');

            if ($('.js-hook-headerMenuDropButton').hasClass('show')) {
                setTimeout(function() {

                    overlay.show();
                    body.addClass('no-scroll_tablet');

                    if (dropMenu !== 'become'){
                    	body.addClass('no-scroll_mobile');
                    }

                    header.dropMenuFn[dropMenu]();

                    $('html').off('click.headerDropMenu').on('click.headerDropMenu', function(event) {
                        if (!$(event.target).is(menu) && !$(event.target).parents('.js-hook-headerMenuDrop-' + button.data('dropMenu')).length) {
                            _close(button, menu);
                        }
                    });
                });
            } else {
                _close();
            }
        } else {
            buttons = $('.js-hook-headerMenuDropButton');
            menus = $('.js-hook-headerMenuDrop');

            buttons.removeClass('show');
            menus.removeClass('show');

            _close();
        }

        function _close(button, menu) {
            if (button) {
                button.removeClass('show');
            }

            if (menu) {
                menu.removeClass('show');
            }

            overlay.hide();
            overlay.removeClass('has-spinner');
            overlay.removeClass('hide_mobile');
            body.removeClass('no-scroll_mobile no-scroll_tablet');
            $('html').off('click.headerDropMenu');
        }
    });

    $('body').on('click', '.js-hook-tab[data-ajax-command] > a', product.loadTabContent);

    this.rootUrl = urlRoot;
    this.lazyLoad.update();
};

header.megaMenuView = function(catId, bassIds) {
	console.log("in header.megaMenuView");
	var megaMenu = $('.js-hook-megamenu[data-categoryId="' + catId + '"]');
	
	if(header.initMegaMenu===true){
		$('.js-hook-spinner').show();
	} 

	if ($(megaMenu).is(':visible')) {
    	$('.js-hook-megamenu').hide();
    } else {
    	$('.js-hook-megamenu').not(megaMenu).hide();
        $(megaMenu).show();
        megaResizer();
    }

	function megaResizer(){
		$('.js-mega-menu-wrapper').height('auto');
		if(window.innerWidth > 1150 && $('.js-mega-menu-wrapper').height() > window.innerHeight ){
			$('.js-mega-menu-wrapper').height(window.innerHeight - 140);
			$('.js-mega-menu-wrapper').css({'overflow-y': 'auto'});
		}
	}
};



header.megaMenuToggle = function(show) {
	console.log("in header.megaMenuToggle");
    var megaMenu = $('.js-megaMenu'),
        backdrop = $('.js-megaMenuBackdrop');

    if (megaMenu.is(':visible') || !!show) {
        megaMenu.hide();
        backdrop.removeClass('show');
    } else {
        megaMenu.show();
        backdrop.addClass('show');
    }
};

header.megaMenuToggleSubList = function(el) {
    var item = $(el).closest('.js-megaMenuItem'),
        items = $('.js-megaMenuItem'),
        subList = item.find('.js-megaMenuSublist');

    if (item.hasClass('expanded')) {
        items.removeClass('expanded');
        items.css({ paddingBottom: this.mainCategoryPaddingBottom });
    } else {
        items.removeClass('expanded');
        items.css({ paddingBottom: this.mainCategoryPaddingBottom });
        item.addClass('expanded');
        item.css({ paddingBottom: subList.height() + 70 + 'px' });
    }
};

//Creates a drop-down menu in the header for handling overcrowding links in the header-eSpot 
header.resizableLinkList = function(btnTitle) {
	
    var list = $("<ul></ul>");
    var dropArrowInner =$("<div></div>").addClass("header__dropdown-more-arrow-inner");
	var dropArrow = $("<div></div>").addClass("header__dropdown-more-arrow header__e-drop").append(dropArrowInner);
    var drop = $("<div></div>").addClass("header__dropdown-more-menu header__e-drop").append(list);
	var dropContainer = $("<div></div>").addClass("header__dropdown-more header__e-drop").append(dropArrow).append(drop).hide();
	
	var link = $("<a></a>").text(btnTitle).addClass("header__buttonLink header__e-drop").click(function(){ $(".header__dropdown-more").toggle(); });
	var icon = $("<i></i>").addClass("fa fa-caret-down");
    var btn = $("<li></li>").addClass("header__links hide_mobile hide_tablet header__e-drop no-select ").css('float','left').append(link).append(icon).append(dropContainer);
    
    btn.hide(); 
    $(".js-header-e-link").before(btn);

    var dropLinks = [];
    var linkObj = {
    	headerLinks: 	[],
    	sizeList: 		[],
    	currentWidth: 	parseInt($(".header__logo").css('max-width'), 10) + 40,
    	currentIndex: 	0
    };
    
    setUpDropDown();
    for(var i = 0; i < linkObj.headerLinks.length; i++){ setUpELinks(); }
	$(window).resize(function() { setUpELinks(); });
	
	function setUpDropDown() {
		
		$(".js-right-box-ul li").map(function(i, el){
        	if (!($(el).hasClass("js-e-link")) && !($(el).hasClass("js-header-e-link")) && ($(el).is(':visible'))) {
        		linkObj.currentWidth = linkObj.currentWidth + $(el).outerWidth(true);
        	}
        });
		
		linkObj.headerLinks = $( ".js-header-e-link ul li" ).map(function(i, el) {
			
                var jEl = $(el).clone();
                jEl.children().removeClass("header__buttonLink");
                var dropBtn = $("<li></li>").addClass("login__links header__e-drop").append($(jEl).html());
                dropBtn.hide();
                dropLinks.push(dropBtn);
                list.append(dropBtn);
                linkObj.sizeList.push($(el).outerWidth(true));
                linkObj.currentWidth = linkObj.currentWidth + $(el).outerWidth(true);
                linkObj.currentIndex = i;
                
                return el;
        });
 	}
	
	function setUpELinks() {
		
		   if(linkObj.currentWidth > $(".js-header-content").width() && linkObj.currentIndex != 0) {
			   
			  	$(dropLinks[linkObj.currentIndex]).show();
				$(linkObj.headerLinks[linkObj.currentIndex]).hide();
				linkObj.currentWidth = linkObj.currentWidth - linkObj.sizeList[linkObj.currentIndex];
				linkObj.currentIndex--;
				btn.show();

		   } else {
			   
		   		if((linkObj.currentWidth + linkObj.sizeList[linkObj.currentIndex + 1] < $(".js-header-content").width()) && (linkObj.currentIndex != dropLinks.length -1)){
		   			linkObj.currentIndex++;
		   			$(dropLinks[linkObj.currentIndex]).hide();
					$(linkObj.headerLinks[linkObj.currentIndex]).show();
					linkObj.currentWidth = linkObj.currentWidth + linkObj.sizeList[linkObj.currentIndex];
		   		}
		   		
		   		if(linkObj.currentIndex == dropLinks.length -1){
		   			btn.hide();
		   		}
		   }
	}

	
}

header.showAssortment = function(thisElem) {

    $('.js-hook-assortment-tab').removeClass('selected');
    $(thisElem).addClass('selected');
	
	var assId = $(thisElem).data('assid');
	var categoryId = $(thisElem).closest('.js-hook-assortment-nav').data("categoryid");
	var megaMenu = $('.js-hook-megamenu[data-categoryId="' + categoryId + '"]');

	$(".js-hook-assortment-content", megaMenu).hide();
	$(".js-hook-assortment-content[data-assId='"+assId+"']", megaMenu).show();
	
}

