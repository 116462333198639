//-----------------------------------------------------------------
// Licensed Materials - Property of IBM
//
// WebSphere Commerce
//
// (C) Copyright IBM Corp. 2016 All Rights Reserved.
//
// US Government Users Restricted Rights - Use, duplication or
// disclosure restricted by GSA ADP Schedule Contract with
// IBM Corp.
//-----------------------------------------------------------------

// NOOP
product.autoLoadFirstTab = function() {
	$('.js-hook-tabsInitialState').removeClass('js-hook-tabsInitialState');
}

product.toggleProductInfo = function(el) {
    var content = $(el);

    content.toggleClass('show');
};