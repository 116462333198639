//-----------------------------------------------------------------
// Licensed Materials - Property of IBM
//
// WebSphere Commerce
//
// (C) Copyright IBM Corp. 2016 All Rights Reserved.
//
// US Government Users Restricted Rights - Use, duplication or
// disclosure restricted by GSA ADP Schedule Contract with
// IBM Corp.
//-----------------------------------------------------------------


var footer = {
	init: function() {
		if ($('#back-to-top').length) {
			$(window).scroll(function() {
				if($(window).scrollTop() >= ($(window).height()/1.33) ){ // 75%
					$('#back-to-top').addClass('show');
				} else {
					$('#back-to-top').removeClass('show');
				}
			});
		}
	},
	ScrollToTop: function(){
        $('html,body').animate({
            scrollTop: 0
        }, 700);
    },
    
}