//-----------------------------------------------------------------
// Licensed Materials - Property of IBM
//
// WebSphere Commerce
//
// (C) Copyright IBM Corp. 2016 All Rights Reserved.
//
// US Government Users Restricted Rights - Use, duplication or
// disclosure restricted by GSA ADP Schedule Contract with
// IBM Corp.
//-----------------------------------------------------------------

services.megaMenuView = function(ajaxResource, catId, callback, bassId, bassIdArr) {
	
	$.ajax({
	      
		type: "GET",
		url: urlRoot + ajaxResource,
        dataType: "html",
        data: {
            langId: WCParamJS.langId,
            storeId: WCParamJS.storeId,
            catalogId: WCParamJS.catalogId,
            categoryId: catId,
            initbass: bassId,
            menuCategoryId: $("body").data("categoryid"),
            menuProductId: $("body").data("productid"),
            USINGAJAX: true,
            requesttype: "ajax"
        },
        success: function(returnedAjaxData) {
            if(services.serviceValidation(returnedAjaxData)){
            	var data = {
        			categoryId: catId,
        			bassId: bassId,
        			bassIdArr: bassIdArr,
        			returnedAjaxData: returnedAjaxData
            	}
                callback(data);
            }
        }
    });
};