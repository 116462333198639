//-----------------------------------------------------------------
// Licensed Materials - Property of IBM
//
// WebSphere Commerce
//
// (C) Copyright IBM Corp. 2016 All Rights Reserved.
//
// US Government Users Restricted Rights - Use, duplication or
// disclosure restricted by GSA ADP Schedule Contract with
// IBM Corp.
//-----------------------------------------------------------------

var campainBlock = campainBlock || {};

/**
 * Adjusts width of the hero carousel wrapper.
 */
campainBlock.adjustElementWidth = function() {
    $(document).on('ready', function() {
        _adjustWrapperWidth();
        $(window).resize(_adjustWrapperWidth);
    });

    function _adjustWrapperWidth() {
        var windowWidth = $(window).width();

        $('.js-hook-campainBlock').css({
            width: windowWidth,
            left: 'calc(50% - ' + windowWidth / 2 + 'px)'
        });
    }
};