//-----------------------------------------------------------------
// Licensed Materials - Property of IBM
//
// WebSphere Commerce
//
// (C) Copyright IBM Corp. 2016 All Rights Reserved.
//
// US Government Users Restricted Rights - Use, duplication or
// disclosure restricted by GSA ADP Schedule Contract with
// IBM Corp.
//-----------------------------------------------------------------

catalog.toggleTab = function(el) {
    var tab = $(el).parent(),
        tabs = $('.tab__button').not(tab);

    tab.parent().removeClass('js-hook-tabsInitialState');

    if ($(window).width() > 767) {
        tabs.removeClass('active');
        //tab.addClass('active');
        tab.toggleClass('active');
    } else {
        tab.toggleClass('active');
        tabs.removeClass('active');
    }
};

catalog.brandsToggleSubList = function(el) {
    var item = $(el).closest('.js-brandItem'),
        items = $('.js-brandItem'),
        subList = item.find('.js-brandSublistb');

    if (item.hasClass('expanded')) {
        items.removeClass('expanded');
        items.css({ paddingBottom: '50px' });
    } else {
        items.removeClass('expanded');
        //items.css({ paddingBottom: this.mainCategoryPaddingBottom });
        items.css({ paddingBottom: '50px' });
        item.addClass('expanded');
        item.css({ paddingBottom: subList.height() + 70 + 'px' });
    }
};

catalog.scrollToAnchor = function(id){
	$('html, body').animate({
        scrollTop: $("#"+id).offset().top - 70
    }, 200);
}


/*
catalog.toggleOverflowArea = function(el) {
    var overflowArea = $(el).closest('.js-hook-productInfoArea');

    overflowArea.toggleClass('show');
};

catalog.checkProductInfoOverflow = function() {
    var overflowArea = $('.js-hook-productInfoArea'),
        overflowContent = overflowArea.find('.js-hook-productInfoContent'),
        previousWidth = null;

    _checkProductInfoOverflow();
    $(window).on('resize', _checkProductInfoOverflow);

    function _checkProductInfoOverflow() {
        if ($(window).width() !== previousWidth) {
            previousWidth = $(window).width();

            var areaHeight = overflowArea.outerHeight() - 40,
                contentHeight = overflowContent.outerHeight(),
                noOverflow = contentHeight < areaHeight;

            overflowArea.toggleClass('content__no-overflow', noOverflow);

            if (noOverflow) {
                overflowArea.removeClass('show');
            }
        }
    }
};*/